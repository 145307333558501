table, thead, tfoot {
  text-align: left;
  border-collapse: collapse;
  border: 1px solid #000;
}

th, td {
  border-left: 1px solid #000;
}

.title {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.63943e98.css.map */
