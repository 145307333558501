table, thead, tfoot {
  text-align: left;
  border: 1px solid black;
  border-collapse: collapse;
}

th, td {
  border-left: 1px solid black;
}

.title {
  margin-bottom: 0;
}
